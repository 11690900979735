import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SettingsProvider } from 'src/app/services/settings';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { Observable } from 'rxjs';
import { AuthService, UserType } from 'src/app/services/auth.service';

@Component({
  selector: 'modal-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatSlideToggleModule,
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class FilterModalComponent implements OnInit {

  dataForm: FormGroup;

  loadingSubProduct:boolean = false;
  filterSubProduct!: string;
  subProducts:any[] = [];

  loadingCategories:boolean = false;
  filterCategories!: string;
  categories:any[] = [];

  loadingUsers:boolean = false;
  filterUsers: string = '';
  users:any[] = [];

  user$: Observable<UserType>;

  type = '';

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private toast: NotificationService,
    private http: HttpClientProvider,
    public xServices: XtrasService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
    ) {
    this.type = data['type'] ?? '';
    this.user$ = this.authService.user$;
    this.dataForm = new FormGroup({
      name:               new FormControl(''),
      cellphone:          new FormControl(''),
      ci_number:          new FormControl(''),
      created_at:         new FormControl(''),
      product_item_ids:   new FormControl(''),
      category_ids:       new FormControl(''),
      user_id:            new FormControl(''),
    }) as FormGroup;
    if(data&&data.form) {
      this.dataForm.patchValue({
        name: data.form?.name ?? '',
        cellphone:  data.form?.cellphone ?? '',
        ci_number:  data.form?.ci_number ?? '',
        created_at:  data.form?.created_at ?? '',
        product_item_ids:  data.form?.product_item_ids ?? '',
        category_ids:  data.form?.category_ids ?? '',
        user_id:  data.form?.user_id ?? '',
      });
    }
    this.initializeApp();
  }

  initializeApp() {
    this.loadingSubProduct = true;
    this.subProducts = [];
    this.http.getRequest(SettingsProvider.getUrl(`model/product-item?is_paginate=0`)).subscribe((result:any) => {
      console.log(result);
      if(result['status']) {
        this.subProducts = result['items'] ?? [];
        this.loadingSubProduct = false;
      } else {
        this.loadingSubProduct = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingSubProduct = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });

    this.loadingCategories = true;
    this.categories = [];
    this.http.getRequest(SettingsProvider.getUrl(`model/category?is_paginate=0`)).subscribe((result:any) => {
      console.log(result);
      if(result['status']) {
        this.categories = result['items'] ?? [];
        this.loadingCategories = false;
      } else {
        this.loadingCategories = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingCategories = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });

    this.loadingUsers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingUsers = false;
      if(result['items']&&result['items'].length > 0) {
        this.users = result['items'];
      } else {
      }
    }, error => {
      console.log(error);
      this.loadingUsers = false;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm(data:any) {
    if(this.type) {
      localStorage.setItem(`formLeads-${this.type}`, JSON.stringify(data ?? {}));
    }
    this.dialogRef.close({data: data});
  }
}