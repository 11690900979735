
import { Injectable } from '@angular/core';

import Swal, { SweetAlertIcon } from 'sweetalert2';
import { XtrasService } from './xtras.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  alertCapYei(message: string, type: SweetAlertIcon, buttons?: any, messages?: any, errors?: any, close?:boolean) {
    console.log(message, type, buttons, messages, errors);
    let title = 'Error';
    let text = message || 'Error';
    if(this.lengthItems(messages)) {
      title = message;
      text = this.textFormat(messages);
    } else {
      title = message || 'Error';
      if(messages != null) {
        text = messages;
      }
    }
    if(this.lengthItems(errors)) {
      title = message;
      if(this.lengthItems(text)) {
        text = text ? text : text + '<hr>' + this.textFormat(errors);
      } else {
        text = this.textFormat(errors);
      }
    } else {
      if(message) {
        title = 'Error';
        text = message;
      }
      if(errors != null) {
        title = message || 'Error';
        text = errors;
      }
    }
    Swal.fire({
      title: title,
      html: text,
      icon: type,
      showCancelButton: buttons ? (buttons['showCancelButton'] ? buttons['showCancelButton'] : false) : false,
      cancelButtonText: buttons ? (buttons['cancelButtonText'] ? buttons['cancelButtonText'] : null) : null,
      showConfirmButton: buttons ? (buttons['showConfirmButton'] ? buttons['showConfirmButton'] : false) : false,
      confirmButtonText: buttons ? (buttons['confirmButtonText'] ? buttons['confirmButtonText'] : null) : null,
      allowOutsideClick: (close ==  true || close == false) ? (close == false ? false : true) : true,
      allowEscapeKey: (close ==  true || close == false) ? (close == false ? false : true) : true,
    });
  }
  
  private textFormat(errors: any) {
    // let data = [];
    let data = '';
    for(let item of errors) {
      // data.push(`<p>${item}</p>`);
      data = data.concat(`<p>${item}</p>`);
    }
    // console.log(data);
    return data;
  }

  private lengthItems(items: any) {
    if(Array.isArray(items)) {
      if(items.length >= 1) {
        return true;
      } else {
        return false;
      }
  } else {
      return false;
    }
  }
}
