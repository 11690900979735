<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        Filtros de búsqueda
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label for="filter-modal_name" class="text-secondary block w-full text-sm font-medium mb-2">Nombre:</label>
                <input type="text" name="name" maxlength="255" formControlName="name" id="filter-modal_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_cellphone" class="text-secondary block w-full text-sm font-medium mb-2">Celular:</label>
                <input type="phone" name="cellphone" maxlength="255" formControlName="cellphone" id="filter-modal_cellphone" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_ci_number" class="text-secondary block w-full text-sm font-medium mb-2">Carnet de identidad:</label>
                <input type="number" name="ci_number" maxlength="255" formControlName="ci_number" id="filter-modal_ci_number" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_created_at" class="text-secondary block w-full text-sm font-medium mb-2">Creado en:</label>
                <input type="date" name="created_at" maxlength="255" formControlName="created_at" id="filter-modal_created_at" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_product_item_id" class="text-secondary block w-full text-sm font-medium mb-2">Sub producto:</label>
                <mat-select id="filter-modal_product_item_id" name="product_item_ids" formControlName="product_item_ids" placeholder="Seleccione una opción" class="custom__mat-select" multiple>
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterSubProduct = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingSubProduct; else elseLoadingSubProduct">
                        <ng-container *ngIf="xServices.lengthItems(subProducts); else elseSubProduct">
                            <mat-option *ngFor="let item of subProducts | filter: filterSubProduct : 'name'" [value]="item['id']" >{{ item?.name ?? '-' }}</mat-option>
                        </ng-container>
                        <ng-template #elseSubProduct>
                            <mat-option [value]="null">No se encontraron sub productos</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingSubProduct>
                        <mat-option [value]="null">Cargando sub productos...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="w-full">
                <label for="filter-modal_category_id" class="text-secondary block w-full text-sm font-medium mb-2">Estado:</label>
                <mat-select id="filter-modal_category_id" name="category_ids" formControlName="category_ids" placeholder="Seleccione una opción" class="custom__mat-select" multiple>
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterCategories = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingCategories; else elseLoadingCategories">
                        <ng-container *ngIf="xServices.lengthItems(categories); else elseCategories">
                            <mat-option *ngFor="let item of categories | filter: filterCategories : 'name'" [value]="item['id']" >{{ item?.name ?? '-' }}</mat-option>
                        </ng-container>
                        <ng-template #elseCategories>
                            <mat-option [value]="null">No se encontraron estados</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingCategories>
                        <mat-option [value]="null">Cargando estados...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <ng-container *ngIf="this.user$ | async as _user">
                <div *ngIf="_user.role_id == 1" class="w-full">
                    <label for="user_id" class="text-secondary block w-full text-sm font-medium mb-2">Vendedor:</label>
                    <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterUsers = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingUsers; else elseLoadingUsers">
                            <ng-container *ngIf="xServices.lengthItems(users); else elseUsers">
                                <mat-option [value]="null" >Ningúno</mat-option>
                                <mat-option *ngFor="let item of users | filter: filterUsers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
                            </ng-container>
                            <ng-template #elseUsers>
                                <mat-option [value]="null">No se encontraron usuarios</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingUsers>
                            <mat-option [value]="null">Cargando usuarios...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            </ng-container>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-2">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <div class="flex flex-wrap gap-2">
                <button mat-button type="button" (click)="submitForm(null)" class="cy__btn btn__primary">
                    <i class="fa-light fa-close"></i> Limpiar
                </button>
                <button mat-button type="button" (click)="submitForm(dataForm.value)" class="cy__btn btn__second">
                    <i class="fa-light fa-check"></i> Filtrar
                </button>
            </div>
        </div>
    </div>
</div>
